<template>
	<div>
		<div class="item__row item__ac">
			<h2>Заявки</h2>
		</div>

		<v-data-table
			:headers="headers"
			:items="feeds"
			:page="page"
			:loading="loading"
			:options.sync="options"
			:server-items-length="totalPage"
		>
			<template v-slot:item.id="{ item, index }">
				{{ index + 1 }}
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.updated_at="{ item }">
				{{ formatDate(item.updated_at) }}
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<i
						class="mdi mdi-pencil"
							@click="show(item.id, item)"
							v-bind="attrs"
							v-on="on"
						></i>
					</template>
					<span>Редактировать</span>
				</v-tooltip>
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<i
							style="margin: 10px"
							class="mdi mdi-trash-can-outline"
							@click="openDeleteModal(item.id)"
							v-bind="attrs"
							v-on="on"
						></i>
					</template>
					<span>Удалить</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<v-dialog v-model="destroyModal" width="500">
			<v-card class="pa-6">
				<h3 class="mb-4">Удалить запись</h3>
				<v-btn type="submit" depressed color="primary" @click="deleteItem()">
					Да
				</v-btn>

				<v-btn depressed color="default" @click="destroyModal = false">
					Отмена
				</v-btn>
			</v-card>
		</v-dialog>
		<v-dialog v-model="openModal" width="500">
			<v-card class="pa-6">
				<v-form
					@submit.prevent="callFunction()"
					ref="form"
					class="sign__page__block"
				>
					<h3 class="mb-4" @click="openModal = true">Редактировать</h3>
					<div class="item__column">
						<v-textarea
							v-model="name"
							filled
							name="input-7-4"
							label="ФИО"
							class="input"
						></v-textarea>
					</div>
					<div class="item__column">
						<v-textarea
							v-model="email"
							filled
							name="input-7-4"
							class="input"
							label="Email"
						></v-textarea>
					</div>
					<div class="item__column">
						<v-textarea
							v-model="message"
							filled
							name="input-7-4"
							label="Сообщение"
							class="input"
						></v-textarea>
					</div>
					<div class="item__column">
						<v-textarea
							v-model="phone"
							filled
							name="input-7-4"
							class="input"
							label="Телефон"
						></v-textarea>
					</div>

					<div class="item__column">
						<v-textarea
							v-model="answer"
							filled
							class="input"
							name="input-7-4"
							label="Ответ"
						></v-textarea>
					</div>

					<v-btn type="submit" depressed color="primary" @click="update()" >
						Сохранить изменения
					</v-btn>

					<v-btn depressed color="default" @click="openModal = false">
						Отмена
					</v-btn>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: ["showModal"],
	name: "Feedback",
	data() {
		return {
			feeds: [],
			me: null,
			sendModal: false,	
			openModal: false,
			description: "",
			id: "",
			loading: false,
			numberOfPages: null,
			totalPage: null,
			page: 0,
			options: {
				itemsPerPage: 10,
				page: 1
			},
			destroyModal: false,
			selectedUser: null,
			type: 0,
			newsId: "",

			headers: [
				{
					text: "№",
					align: "start",
					sortable: false,
					value: "id"
				},
				{ text: "ФИО", value: "name" },
				{ text: "Email", value: "email" },
				{ text: "Сообщение", value: "message" },
				{ text: "Телефон", value: "phone" },
				{ text: "Дата создание", value: "created_at" },
				{ text: "Дата редактирование", value: "updated_at" },
				{ text: "Ответ", value: "answer" },
				{ text: "Действие", value: "action" }
			]
		};
	},
	methods: {
			fetch() {
			this.loading = true;

			this.$axios({
				method: "get",
				url:
					this.$API_URL +
					this.$API_VERSION +
					"feedback?per_page=" +
					this.options.itemsPerPage +
					"&page=" +
					this.options.page,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.feeds = response.data.data;
					this.items = response.data.data;
					this.loading = false;
					this.numberOfPages = response.data.total;
					this.totalPage = response.data.total;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(date) {
			if (date) {
				let splitter = date.includes("T") ? "T" : " ";
				let d = date.split(splitter)[0].split("-");
				let time = date.split(splitter)[1].split(":");
				return d[2] + "-" + d[1] + "-" + d[0] + " " + time[0] + ":" + time[1];
			}
		},
		removeFile(fileId) {
			this.$axios({
				method: "delete",
				url: this.$API_URL + this.$API_VERSION + "page/file/" + fileId,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.$toast.open({
						message: response.data.message,
						type: "success",
						position: "bottom",
						duration: 4000,
						queue: true
					});
					this.fetch();
					this.openModal = false;
				})
				.catch((error) => {
					console.warn(error);
				});
		},	
		getUser() {
			this.$axios({
				method: "get",
				url: this.$API_URL + this.$API_VERSION + "me",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.me = response.data;
				})
				.catch((error) => {
					console.warn(error);
				});
		},	
		// chooseTypeFunction(type) {
		// 	this.type = type;
		// 	this.openModal = true;
		// },

		openSendModal(item) {
			this.sendModal = true;
			this.id = item.id;
		},
		updateData(obj, id) {
			console.log(obj);
			this.$axios
				.put(
					
					this.$API_URL + this.$API_VERSION + "feedback/" + id, 
					{
						answer:	obj.answer,
						// type: this.$route.query.type
					},
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("access_token")}`
						}
					}
				)
				.then((response) => {
					this.$toast.open({
						message: response.data.message,
						type: "success",
						position: "bottom",
						duration: 4000,
						queue: true
					});

					this.fetch();
					this.openModal = false;
				})
				.catch((error) => {
					if (error.response && error.response.status == 422) {
						this.$toast.open({
							message: "Заполните все поля",
							type: "error",
							position: "bottom",
							duration: 4000,
							queue: true
						});
					}
				});
		},			
		// callFunction() {
		// 	this.type == 1 && this.update();
		// },
		openDeleteModal(id) {
			this.destroyModal = true;
			this.selectedUser = id;
		},
		
		show(id, item) {
			this.id = id;
			this.openModal = true;
			this.name = item.name;
			this.email = item.email;
			this.message = item.message;
			this.phone = item.phone;
			this.answer = item.answer;
		},
		makeJson(item) {
			if (item) return JSON.parse(item);
		},

		deleteItem() {
			this.$axios({
				method: "delete",
				url: this.$API_URL + this.$API_VERSION + "feedback/destroy/" + this.selectedUser,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.title = response.data.title;
					this.description = response.data.description;
					this.answer = response.data.answer;
					this.fetch();
					this.destroyModal = false;
					this.openModal = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		update() {
			let obj = {
				// name: this.name,
				// email: this.email,
				// message: this.message,
				// phone: this.phone,
				answer: this.answer,
				// type: this.$route.query.type
			};

			this.updateData(obj, this.id);
			this.fetch();
		},
	},
	mounted() {
		this.fetch();
		this.getUser();
	},
	beforeMount() {},
	watch: {
		options: {
			handler(val) {
				if (val.itemsPerPage < 0) {
					val.itemsPerPage = this.totalPage;
					this.fetch();
				} else {
					this.fetch();
				}
			}
		},

		deep: true
	}
};
</script>
